<template>
  <div>
    <div class="p-d-flex p-jc-end" style="background-color: white;">
      <div class="p-d-inline-flex">
        <Button v-if="getIsEditable"
                class="p-button-rounded p-button-text p-button-outlined p-button-icon-only p-button-success"
                icon="pi pi-plus"
                v-tooltip.bottom="_t('label_Add_widget')"
                @click="sidebar = true"/>
        <Button v-if="!getIsEditable"
                class="p-button-rounded p-button-text p-button-outlined p-button-icon-only p-button-secondary"
                icon="pi pi-pencil"
                v-tooltip.bottom="_t('label_edit')"
                @click="setIsEditable(true)"/>
        <Button v-if="getIsEditable"
                class="p-button-rounded p-button-text p-button-outlined p-button-icon-only p-button-secondary"
                icon="pi pi-save"
                v-tooltip.bottom="_t('Save')"
                @click="saveWidgets"/>
      </div>
    </div>
    <div v-if="getLines" class="dashboard-wrapper">
      <draggable
          v-for="(line, number) in getLines"
          :key="number"
          :disabled="!getIsEditable"
          :list="line"
          :class="`p-d-flex p-flex-row dragDropLine ${line.length !== 1 || line.some((el) => el.key === 'lastAlarms') ? '' : 'customWidth'} ${getIsEditable ? 'draggable' : ''}`"
          group="list"
          itemKey="key"
          @end="isDraggable = false"
          @start="isDraggable = true"
      >
        <template #item="{ element, index }">
          <div :class="{ 'not-draggable': !getIsEditable }" class="p-d-inline-flex"
               style="width: 100%; position: relative;">
            <Button
                v-if="getIsEditable"
                class="p-button-icon-only p-button-text p-button-rounded p-button-danger"
                icon="pi pi-trash"
                style="position: absolute; top: 10px; right: 10px;"
                type="button"
                @click="removeWidgetFromLine({ line: number, index })"
            />
            <component
                :is="module(element.key)"
                v-model:config="element.config"
                :class="`widget-${element.key}`"
                class="widget"
                style="width: 100%"
            ></component>
          </div>
        </template>
      </draggable>
    </div>

    <Sidebar v-model:visible="sidebar" position="right">
      <h5>{{ _t('label_Widgets') }}:</h5>
      <draggable
          :list="availableWidgets"
          class="available-widgets"
          group="list"
          itemKey="key"
          @end="isDraggable = false"
          @start="isDraggable = true"
      >
        <template #item="{ element }">
          <Card :class="`widget-${element.key}`" class="widget">
            <template #content>
              <img :src="element.preview" style="width: 100%; height: 100%;"/>
              <div class="title">{{ element.title }}</div>
              <div class="card-overlay">
                <Button class="p-button-success" @click="addWidget(element.key)">{{ _t('label_Add_widget') }}</Button>
              </div>
            </template>
          </Card>
        </template>
      </draggable>
    </Sidebar>
  </div>
</template>

<script>
import * as widgets from './widgets';
import draggable from 'vuedraggable';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "dashboard",
  components: {draggable},
  data: () => ({
    sidebar: false,
    isDraggable: false,
  }),
  created() {
  },
  mounted() {
    this.callGetLines();
  },
  methods: {
    ...mapActions({
      'callGetLines': 'dashboard/callGetLines',
      'callSetLines': 'dashboard/callSetLines',
    }),
    ...mapMutations({
      'setIsEditable': 'dashboard/setIsEditable',
      'addWidgetToLine': 'dashboard/addWidget',
      'removeWidgetFromLine': 'dashboard/removeWidget',
    }),
    module(widgetName) {
      return this.components[widgetName].default;
    },
    addWidget(widgetName) {
      if (!widgets[widgetName]) return;
      let line = this.getLines.findIndex(i => i.length === 0) ?? this.getLines.length - 1;
      this.addWidgetToLine({line, widget: {key: widgetName, config: widgets[widgetName].default.configuration}});
    },
    saveWidgets() {
      this.callSetLines();
    }
  },
  computed: {
    ...mapGetters({
      'getLines': 'dashboard/getLines',
      'getIsEditable': 'dashboard/getIsEditable',
    }),
    availableWidgets: function () {
      return Object.keys(widgets).map(i => ({
        key: i,
        title: this._t(widgets[i].default.title),
        preview: widgets[i].default.preview,
      }));
    },
    components: () => widgets,
  },
}
</script>

<style scoped>
.dragDropLine {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.customWidth {
  width: 45rem;
  margin: 0 auto;
}

.draggable {
  border: 1px dashed gray;
  min-height: 50px;
}

.card-overlay {
  display: none;
}

.available-widgets .widget {
  position: relative;
}

.available-widgets .widget:hover .card-overlay {
  display: block;
  background-color: #00000030;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.card-overlay .p-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-card-content .title {
  position: absolute;
  left: 10px;
  right: 10px;
  padding: 0;
  margin: 0;
  bottom: 10px;
  overflow: hidden;
  background-color: #fff;
}
</style>
