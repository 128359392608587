<template>
  <template v-if="config.to && config.label">
    <router-link
        :to="config.to"
        class="router-link p-d-inline-flex"
        style="width: 100%"
    >
      <Card class="p-as-stretch" style="width: 100%">
        <template #content>
          <div class="p-text-center" style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <i :class="config.icon" style="fontSize: 2rem; marginRight: 1rem;"></i>
            <strong>{{ _t(config.label) }}</strong>
          </div>
        </template>
      </Card>
    </router-link>
  </template>
  <Card v-else>
    <template #title>{{_t('label_Menu_item')}}</template>
    <template #content>
      <Button class="p-button-success" @click="configureDialog = true">{{_t('label_Configure')}}</Button>
      <Dialog v-model:visible="configureDialog" :modal="true">
        <template #header>
          <h3>{{_t('label_Configuration')}}</h3>
        </template>

        <Dropdown
            v-model="selectedRoute"
            :options="availableMenuItems"
            optionLabel="value"
            :placeholder="_t('label_Select_menu_item')"
        />

        <template #footer>
          <Button class="p-button-text" icon="pi pi-times" :label="_t('label_no')" @click="configureDialog=false"/>
          <Button autofocus class="p-button-success" icon="pi pi-check" :label="_t('label_yes')" @click="saveConfiguration"/>
        </template>
      </Dialog>
    </template>
  </Card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "menu-item",
  title: 'label_Menu_item',
  preview: 'assets/layout/images/widgets/menu-item.png',
  props: {
    config: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data: () => ({
    configureDialog: false,
    selectedRoute: null,
  }),
  methods: {
    saveConfiguration() {
      this.configureDialog = false;
      this.$emit('update:config', {
        to: this.selectedRoute.to,
        label: this.selectedRoute.label,
        icon: this.selectedRoute.icon,
        key: this.selectedRoute.key,
      });
    },
    collectMenuRecursively(menu) {
      let items = [];
      for (const menuItem of menu) {
        if (menuItem.items && menuItem.items.length) {
          items = items.concat(this.collectMenuRecursively(menuItem.items));
        } else {
          items.push(menuItem);
        }
      }
      return items;
    },
  },
  computed: {
    ...mapGetters({
      'getMenu': 'getMenu',
    }),
    availableMenuItems: function () {
      return this.collectMenuRecursively(this.getMenu)
          .map(i => ({...i, label: i.label, icon: i.icon, value: this._t(i.label)}))
          .filter(i => !i.disabled);
    }
  }
}
</script>

<style scoped>
:deep(.p-card .p-card-body), :deep(.p-card  .p-card-content) {
  height: 100%;
}
</style>
