<template>
  <Card>
    <template #title>
      <h3 v-if="config && config.name">{{ _t(config.name) }}</h3>
      <h3 v-else>{{ _t('label_Resources') }}</h3>
    </template>
    <template #content>
      <template v-if="!config || !config.key">
        <Dropdown v-model="selectedDiagram" :options="diagrams" optionLabel="name" placeholder="Select a Diagram"/>
        <Button :label="_t('Save')" class="p-button-success" icon="pi pi-check" @click="saveConfiguration"/>
      </template>
      <div v-else class="p-text-center">
        <div v-if="config.key === 'cpuTime'" class="p-d-flex p-flex-row p-jc-between">
          <div v-if="getResources && getResources.cpuInfo" class="p-d-inline-flex p-text-bold"
               style="min-width: 100px;">{{ _t('label_CPU_time') }}: {{
              getResources.cpuInfo.cpuTime
            }}
          </div>
        </div>
        <div v-if="config.key === 'diskUsage'" class="p-d-inline-block">
          <Speedometer
              v-if="getResources && getResources.diskInfo"
              :invert="true"
              :max="getResources.diskInfo.total"
              :title="_t('label_Disk_usage')"
              :value="getResources.diskInfo.free"
              unit="Gb"
          />
          <Skeleton v-else class="p-d-inline-flex" height="100" width="100%"/>
        </div>
        <div v-if="config.key === 'memoryUsage'" class="p-d-inline-block">
          <Speedometer
              v-if="getResources && getResources.memoryInfo"
              :invert="true"
              :max="getResources.memoryInfo.total"
              :title="_t('label_Memory_usage')"
              :value="getResources.memoryInfo.free"
              unit="Mb"
          />
          <Skeleton v-else class="p-d-inline-flex" height="100" width="100%"/>
        </div>
        <div v-if="config.key === 'cpuLoad'" class="p-d-inline-block">
          <Speedometer
              v-if="getResources && getResources.cpuInfo"
              :hide-value2="true"
              :hide-value3="true"
              :title="_t('label_CPU_load')"
              :value="getResources.cpuInfo.cpuLoad"
              unit="%"
          />
          <Skeleton v-else class="p-d-inline-flex" height="100" width="100%"/>
        </div>
        <div v-if="config.key === 'sysCpuLoad'" class="p-d-inline-block">
          <Speedometer
              v-if="getResources && getResources.cpuInfo"
              :hide-value2="true"
              :hide-value3="true"
              :title="_t('label_System_CPU_load')"
              :value="getResources.cpuInfo.systemCpuLoad"
              unit="%"
          />
          <Skeleton v-else class="p-d-inline-flex" height="100" width="100%"/>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Speedometer from '@/components/Speedometer';

export default {
  components: {
    Speedometer,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  name: 'resources',
  title: 'label_Resources',
  preview: 'assets/layout/images/widgets/speedometer.png',
  isLoading: false,
  data: function () {
    return {
      selectedDiagram: null,
    }
  },
  mounted() {
    this.callResources();
  },
  methods: {
    ...mapActions({
      'callResources': 'dashboard/callResources',
    }),
    saveConfiguration() {
      this.$emit('update:config', {key: this.selectedDiagram.key, name: this.selectedDiagram.value});
    },
  },
  computed: {
    ...mapGetters({
      'getResources': 'dashboard/getResources',
    }),
    diagrams: function () {
      return [
        {key: 'cpuTime', name: this._t('label_CPU_time'), value: 'label_CPU_time'},
        {key: 'diskUsage', name: this._t('label_Disk_usage'), value: 'label_Disk_usage'},
        {key: 'memoryUsage', name: this._t('label_Memory_usage'), value: 'label_Memory_usage'},
        {key: 'cpuLoad', name: this._t('label_CPU_load'), value: 'label_CPU_load'},
        {key: 'sysCpuLoad', name: this._t('label_System_CPU_load'), value: 'label_System_CPU_load'},
      ]
    }
  },
}
</script>

<style scoped>

</style>
